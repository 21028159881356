:root {
    --color-primary: #d59017;
    --color-secondary: #387781;
    --color-background: #f3f3f3;
}

@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap");

html {
    font-size: 62.5% !important; // 1rem = 10px, 10px/16px = 62.5%
    scroll-behavior: smooth;
    overflow-x: hidden !important;
}

body {
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.8;
    color: #111111;
    text-align: left;
    background-color: #fff;
    overflow-x: hidden !important;
}

.navheader {
    position: relative;
    padding: 1rem 0;

    &_nav {
        display: flex;
        justify-content: flex-end;
        align-self: stretch;
        align-items: center;
        max-height: 100%;

        @media only screen and (max-width: 768px) {
            visibility: hidden;
        }

        &-ul {
            display: flex;
            flex-wrap: wrap;
            padding: 2rem 0;
            margin-bottom: 0;
            list-style: none;
        }

        &-li {
            margin: 0 1rem;

            &--link {
                color: var(--color-secondary);
                font-family: "Open Sans", sans-serif;
                font-size: 1.4rem;
                font-weight: 700;
                letter-spacing: 1px;
                text-align: center;
                border-radius: 5rem;
                border: 2px solid;
                border-color: #d59017 !important;
                color: #d59017 !important;
                background-color: transparent !important;
                padding: 1rem 3rem !important;

                @media only screen and (max-width: 768px) {
                    padding: 1rem !important;
                }

                &:hover {
                    text-decoration: none;
                    background-color: var(--color-primary) !important;
                    color: #fff !important;
                    cursor: pointer;
                }
            }
        }
    }
}

.Navbar {
    font-family: "Poppins", Arial, sans-serif;
    background: #ffffff !important;
    z-index: 1000 !important;
    max-width: 100%;
    width: 100%;
    transition: 0.5s all ease-in-out;
    position: relative;

    #navbar-wrap {
        display: flex;
        align-items: flex-start;
        li {
            a {
                padding: 0;
            }
        }
    }

    @media only screen and (max-width: 980px) {
        display: flex !important;
        justify-content: space-between !important;
        align-items: center !important;
        flex-wrap: nowrap !important;
    }

    &_image {
        position: absolute;
        top: -6rem;
        display: flex !important;
        align-items: center;
        gap: 2rem;

        @media only screen and (max-width: 980px) {
            gap: 1rem;
            top: -8rem;
        }

        &-img {
            width: 100%;
            max-width: 100%;
            height: 10rem !important;

            @media only screen and (max-width: 980px) {
                height: 8rem !important;
            }
        }

        &-img2 {
            display: inline;
            width: 8rem !important;
            max-width: 100%;
            margin-left: 3rem;
            position: relative;

            &:hover {
                cursor: pointer;
            }
        }

        &-content {
            &--name {
                font-family: "Roboto", sans-serif;
                font-size: 3.6rem;
                font-weight: 400;
                line-height: 1.1;
                color: #111111;

                @media only screen and (max-width: 980px) {
                    font-size: 3rem;
                }
            }

            &--designation {
                font-family: "Roboto", sans-serif;
                font-size: 2.4rem;
                font-weight: 400;
                line-height: 1.1;
                color: #111111;
            }
        }
    }

    &_nav {
        display: flex !important;
        // justify-content: space-between !important;
        transition: all 5s fade-in;

        @media only screen and (max-width: 980px) {
            margin-top: 2rem;
        }

        &-item {
            display: inline-block;
            margin-bottom: 1rem !important;
            margin-right: 0 !important;
        }

        &-link {
            color: #111111;
            display: inline-flex;
            align-items: center;
            white-space: normal;
            border-radius: 0.4rem;
            font-size: 1.6rem;
            letter-spacing: 0.04em;
            font-style: normal;
            font-weight: 500;
            margin: 0 1rem;
            text-transform: none !important;
            // padding: 1rem 2rem !important;
            text-decoration: none;

            &:hover {
                text-decoration: none;
                border-radius: 0.5rem !important;
                background-color: var(--color-primary);
                color: #fff !important;
                padding: .2rem .7rem !important;
                transition: .2s all ease-in-out;
                cursor: pointer;
            }
        }
    }
}

.navbar-active {
    position: fixed !important;
    top: 0;
    left: 0;
}

.navbar-active .Navbar_image {
    top: 0 !important;
}

.navbar-active .Navbar_image-img {
    width: 8rem;
    height: 4rem;
    margin-bottom: 1rem;
}

.navbar-active .Navbar_nav {
    padding: 0.3rem 0 !important;
    margin-top: 0.5rem !important;

    @media only screen and (max-width: 980px) {
        margin-top: 7rem !important;
    }
}

.toggle-button {
   /*  position: absolute;
    top: -4rem;
    right: 3rem; */
    font-size: 2.4rem;
    color: var(--color-primary);
    background: #fff;
    font-weight: 700;
}

.swiper {
    padding-bottom: 6rem;
}

.swiper-slide {
    position: relative !important;
}

.navbar-active {
    position: fixed;
    border-radius: 0;
    background: #232a34;
    box-shadow: 0px 6rem 6rem rgba(0, 0, 0, 0.1);
    color: #fff !important;
    width: 100%;
    max-width: 100%;
    top: 0;
    left: 0;
    z-index: 10000 !important;
    overflow-x: hidden !important;
}

.carousel_content {
    position: relative;
    z-index: 99;
    &-1 {
        /* background: url("/assets/img/Sanstitre1.mp4");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center; */
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center !important;
        justify-content: center !important;
        text-align: center;
        z-index: 10;
        overflow: hidden;
        color: #212529;
        width: 100%;
        max-width: 100%;
        height: 90vh;
        position: relative;
        z-index: 0;

        #hpVideo{   
            position: absolute;
            left: 0px;
            top: 0px;
            min-width: 100%;
            min-height: 100%;
            z-index: -1
        }    

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #000;
            opacity: 0.3;
            z-index: -1;
            transition: 0.5s;
        }
    }

    &-para {
        @media only screen and (max-width: 768px) {
            padding: 0 !important;
        }

        &--head {
            margin-top: 0px;
            font-family: "Roboto", sans-serif;
            padding: 0;
            color: #ffffff;
            font-size: 6.6rem;
            font-weight: 100 !important;
            text-shadow: none;
            font-style: normal;
            letter-spacing: 0.2rem;
            text-transform: uppercase;
            line-height: 1.1;
            -webkit-box-shadow: none;
            box-shadow: none;
            -webkit-animation-delay: 1.4s;
            animation-delay: 1.4s;

            @media only screen and (max-width: 1000px) {
                font-size: 5rem;
            }

            @media only screen and (max-width: 768px) {
                font-size: 4rem;
            }
        }

        &--p {
            font-family: "Open Sans", sans-serif;
            line-height: 2.8rem;
            padding: 0 10rem;
            color: #fff;
            font-size: 2rem;
            text-shadow: none;
            font-style: normal;
            font-weight: normal;
            letter-spacing: 0.1rem;
            -webkit-box-shadow: none;
            box-shadow: none;
            -webkit-animation-delay: 2.1s;
            animation-delay: 2.1s;
            margin-bottom: 4rem !important;

            @media only screen and (max-width: 768px) {
                padding: 0 !important;
            }
        }

        &--btn {
            color: var(--color-secondary);
            font-family: "Open Sans", sans-serif;
            font-size: 1.6rem;
            font-weight: 700;
            letter-spacing: 1px;
            text-align: center;
            border-radius: 5rem;
            border: 2px solid;
            border-color: #d59017 !important;
            color: #fff !important;
            background-color: var(--color-primary) !important;
            padding: 1.2rem 7.8rem 1.3rem !important;
            text-transform: uppercase;

            @media only screen and (max-width: 768px) {
                padding: 1.2rem 5rem 1.3rem !important;
            }

            @media only screen and (max-width: 580px) {
                padding: 1.2rem 2.5rem 1.3rem !important;
                width: 100%;
                max-width: 100%;
            }

            &:hover {
                text-decoration: none;
                background-color: var(--color-secondary) !important;
                border-color: var(--color-secondary) !important;
                color: #fff !important;
                cursor: pointer;
            }
        }
    }
}

#card {
    height: 55rem;
    @media (max-width: 1200px) {
        height: 70rem;
    }
    @media (max-width: 1040px) {
        height: 75rem;
    }
    @media (max-width: 990px) {
        height: fit-content;
        margin-bottom: 1rem;
    }
}

.cards {
    overflow: hidden !important;
    margin: 0 10rem;
    @media only screen and (max-width: 768px) {
        margin: 0 !important;
    }
    &_header {
        text-align: center;
        overflow-x: hidden !important;
        &-line {
            height: 0.6rem;
            transform-origin: left center 0px;
            width: 79.5rem;
            margin: 3.3rem auto 0;
            border-color: #d59017;
            color: var(--color-primary) !important;
            background-color: var(--color-primary) !important;
        }

        &-title {
            font-family: "Playfair Display", serif !important;
            font-size: 3.6rem;
            line-height: 1.1;
            color: var(--color-primary);
            line-height: 1.6;
            font-style: italic;
            font-weight: 700;
            width: 71.7rem;
            margin: 2.6rem auto 0;
            overflow-x: hidden !important;

            @media only screen and (max-width: 768px) {
                font-size: 2.6rem;
                width: 100%;
            }
        }
    }

    &_row {
        margin: 7rem 0;
    }

    &_card {
        background-image: none;
        box-shadow: -2px 2px 8px 0 #999;
        animation-duration: 1000ms;
        border-color: #c9c0b6;
        padding: 3rem;

        &-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;

            &--svg {
                height: 10.6rem;
                width: 10.6rem;
                background-image: none;
                margin: 1rem 0;
                color: #d59017 !important;
                fill: #d59017;
                text-align: center;
            }
        }

        &-title {
            font-family: "Playfair Display", serif !important;
            font-size: 2rem;
            line-height: 1.1;
            color: #111;
            margin: 2.4rem 0 0;
            letter-spacing: 0px;
            font-style: italic;
            font-weight: 700;
            text-transform: none;
            text-align: center;
            padding: 0 2rem !important;
        }

        &-p {
            font-family: "Open Sans", sans-serif;
            word-wrap: break-word;
            position: relative;
            padding: 0;
            color: #111;
            font-size: 1.6rem;
            margin: 1.1rem 0 0;
            text-align: center;
            font-size: 1.1rem;
            @media (max-width: 990px) {
                font-size: 1.4rem;
            }
        }
    }
}

.about {
    padding: 7rem 0 !important;

    &_content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &-title {
            font-family: "Playfair Display", serif !important;
            font-size: 5rem;
            line-height: 1.1;
            font-weight: 500;
            color: var(--color-primary);
            margin-bottom: 3rem !important;
            margin-top: 1rem !important;
            @media (max-width: 768px){
                font-size: 4rem;
            }
        }

        &-p {
            font-family: "Open Sans", sans-serif;
            position: relative;
            color: #111;
            font-size: 1.8rem;
            margin: 1.1rem 0 0;
            text-align: center;
            line-height: 1.8;
            margin-bottom: 4rem !important;

            @media only screen and (max-width: 768px) {
                padding: 0 !important;
                margin: 0 !important;
                text-align: center !important;
            }
        }

        &-ul {
            list-style: none;
        }
    }

    &_image {
        object-fit: cover;
        display: block;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;

        &-img {
            width: 100%;
            max-width: 100%;
        }
    }
}

.pr-5 {
    @media only screen and (max-width: 768px) {
        padding: 0 !important;
        margin: 0 !important;
    }
}
.pl-5 {
    @media only screen and (max-width: 768px) {
        padding: 0 !important;
        margin: 0 !important;
    }
}

.mr-5 {
    @media only screen and (max-width: 768px) {
        padding: 0 !important;
        margin: 0 !important;
    }
}

.ml-5 {
    @media only screen and (max-width: 768px) {
        padding: 0 !important;
        margin: 0 !important;
    }
}

.px5 {
    @media only screen and (max-width: 768px) {
        padding: 0 !important;
        margin: 0 !important;
    }
}

.mx5 {
    @media only screen and (max-width: 768px) {
        padding: 0 !important;
        margin: 0 !important;
    }
}

.projects {
    padding: 7rem 0 !important;
    background-color: var(--color-secondary);
    color: #ffffff;

    &_content {
        padding: 3rem 5rem 3rem 3rem;

        @media only screen and (max-width: 768px) {
            padding: 0 !important;
            margin: 2rem 1rem !important;
        }

        &-title {
            font-family: "Playfair Display", serif !important;
            font-size: 3.6rem;
            line-height: 1.1;
            font-weight: 500;
            color: #fff;
            margin-bottom: 3rem;
            font-style: italic;
        }

        &-p {
            font-family: "Open Sans", sans-serif;
            word-wrap: break-word;
            position: relative;
            padding: 0;
            color: #fff;
            font-size: 1.8rem;
            margin: 1.1rem 0 0;
            line-height: 1.6;
            margin: 5.4rem 0 0 !important;
        }
    }

    &_images {
        position: relative;

        @media only screen and (max-width: 990px) {
            display: flex;
        }

        @media only screen and (max-width: 600px) {
            flex-direction: column;
            align-items: center;
        }

        &-1 {
            height: 27.7rem;
            background-image: url("/assets/img/2.png");
            background-position: 50% 50%;
            width: 27.7rem;
            margin: -2.1rem -7.1rem 0 auto;
            border-radius: 50% !important;
            object-fit: cover;
            display: block;
            background-size: cover;
            position: absolute;
            top: 2rem;

            @media only screen and (max-width: 990px) {
                position: relative;
                top: 0;
                margin: 0 1rem !important;
            }
        }

        &-2 {
            height: 24.4rem;
            background-image: url("/assets/img/4.png");
            background-position: 50% 50%;
            width: 24.4rem;
            margin: 0 -4.8rem 0 auto;
            border-radius: 50% !important;
            object-fit: cover;
            display: block;
            background-size: cover;
            position: absolute;
            top: 30rem;
            left: 0;

            @media only screen and (max-width: 990px) {
                position: relative;
                top: 0;
                margin: 0 1rem !important;
            }
        }

        &-3 {
            height: 26.5rem;
            background-image: url("/assets/img/9.png");
            background-position: 50% 50%;
            width: 26.5rem;
            margin: 13rem -2.5rem 0 auto;
            border-radius: 50% !important;
            object-fit: cover;
            display: block;
            background-size: cover;
            position: absolute;
            right: -7rem;
            top: 4rem;

            @media only screen and (max-width: 990px) {
                position: relative;
                top: 0;
                right: 0;
                margin: 0 1rem !important;
            }
        }

        &-img {
            background-image: url("/assets/img/9.png");
            background-position: 50% 50%;
            min-height: 60.6rem;
            max-height: 100%;
        }
    }
}

.nature {
    background-image: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.6),
            rgba(0, 0, 0, 0.6)
        ),
        url("/assets/img/2.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    padding: 5rem 0;

    &_content {
        display: flex;
        flex-direction: column;
        align-items: center;

        &-title {
            font-family: "Playfair Display", serif !important;
            font-size: 3.6rem;
            line-height: 1.1;
            font-weight: 500;
            color: var(--color-primary);
            margin-bottom: 3rem;
            margin: 2rem 0;
            text-align: center;
        }

        &-p {
            font-family: "Open Sans", sans-serif;
            word-wrap: break-word;
            padding: 0;
            color: #fff;
            font-size: 2.4rem;
            line-height: 1.6;
            font-weight: 400;
            text-align: center;
            font-style: italic;
            margin: 1.5rem 15rem 0;

            @media only screen and (max-width: 990px) {
                margin: 0 1rem !important;
            }
        }
    }
}

.digital {
    &_left {
        width: 33.4rem;
        height: 67.5rem;
        margin: 2.8rem auto 0 -6.7rem;
        color: #ffffff;
        background-color: #d59017;
        border-radius: 4rem;
        position: relative;

        @media only screen and (max-width: 768px) {
            overflow-x: hidden;
            width: 100%;
            margin-left: -2rem !important;
        }
    }

    &_image {
        background-image: url("/assets/img/6.png");
        min-height: 60rem;
        background-position: 50% 50%;
        // border: 1.8rem solid transparent;
        width: 100% !important;
        max-width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        top: 10%;
        border-radius: 10px;

        @media only screen and (max-width: 768px) {
            overflow-x: hidden;
            margin-left: -2rem !important;
        }
    }

    &_content {
        padding: 3rem;
        margin-left: 4rem;
        overflow-x: hidden !important;

        @media only screen and (max-width: 768px) {
            margin-left: 1rem !important;
            padding: 1rem !important;
        }

        &-ul {
            list-style: none;
        }

        &-title {
            font-family: "Playfair Display", serif !important;
            font-size: 3.6rem;
            line-height: 1.1;
            font-weight: 700;
            color: var(--color-primary);
            margin-bottom: 3rem;
            font-style: italic;
            margin: 3rem auto 0 0 !important;
            text-align: left;
        }

        &-p {
            font-family: "Open Sans", sans-serif;
            padding: 0;
            color: #111;
            font-size: 1.7rem;
            line-height: 1.6;
            font-weight: 400;
            text-align: left;
        }

        &-boxes {
            margin-left: 5rem;

            @media only screen and (max-width: 768px) {
                margin-left: 1rem !important;
            }

            &--box2 {
                position: relative;
                width: 100%;
                max-width: 100%;
                min-height: 30rem;
                padding: 3rem 2rem;
                background-color: rgba(56, 119, 129, 0.5);
                border-radius: 3.5rem;
            }

            &--title {
                font-family: "Roboto", sans-serif;
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: 0.2rem;
                font-size: 2.4rem;
            }
        }
    }
}

.primary-bg {
    background: var(--color-primary) !important;
}

.footer {
    color: #ffffff;
    background-color: #387781;
    padding: 4rem 0;

    &_content {
        margin-top: 4rem;
        margin-bottom: 6rem;

        @media only screen and (max-width: 768px) {
            margin-left: 1rem;
            margin-right: 1rem;
        }

        &-title {
            font-family: "Roboto", sans-serif;
            font-weight: 700;
            letter-spacing: 0.2rem;
            font-size: 2.4rem;
            color: #fff;
            line-height: 1.2;
            margin-bottom: 3rem;
        }

        &-p {
            font-family: "Open Sans", sans-serif;
            padding: 0;
            color: #fff;
            font-size: 2rem;
            line-height: 1.6;
            font-weight: 400;
            text-align: left;
        }

        &-ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        &-li {
            margin: 0.5rem 0;

            &--link {
                font-family: "Open Sans", sans-serif;
                padding: 0;
                color: #c9c0b6;
                font-size: 2rem;
                line-height: 1.6;
                font-weight: 400;
                text-align: left;

                &:hover {
                    text-decoration: none;
                    color: #9c9790;
                    cursor: pointer;
                }
            }
        }

        &-logo2 {
            display: flex;
            justify-content: center;
        }

        &-logo {
            margin: 3rem auto;

            @media only screen and (max-width: 768px) {
                display: flex;
                justify-content: center;
            }

            &--img {
                height: 20rem !important;
            }

            &--img2 {
                height: 10rem !important;
            }
        }

        &-map {
            margin-top: 1rem;
        }
    }

    &_bottom {
        color: #ffffff;
        background-color: #333333;
        padding: 1.6rem;

        &-p {
            text-align: center;
            a {
                font-family: "Open Sans", sans-serif;
                padding: 1.6rem;
                color: #fff;
                font-size: 1.6rem;
                line-height: 1.6;
                font-weight: 400;
            }
        }
        @media (max-width: 768px){
            margin-bottom: 120px;
        }
    }
}

.extramargin {
    padding-left: 3rem;
    padding-right: 3rem;
    margin-left: 3rem;
    margin-right: 3rem;

    @media only screen and (max-width: 768px) {
        padding: 0 !important;
        margin: 0 !important;
    }
}

.modal-content {
    padding: 2rem !important;
}

.modal-header {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
}

.modal-title {
    font-size: 2.4rem;
    line-height: 1.1;
    font-weight: 400;
    color: #00cccc;
    text-align: center;
    border-bottom: 1px solid #00cccc;
}

.model-input {
    color: #111111;
    background-color: #eee !important;
    display: block;
    width: 100%;
    padding: 1rem 1.2rem !important;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    border: none;
    height: auto;
    font-size: 1.8rem;
    margin: 1rem 0;
}

.model-button {
    display: flex;
    justify-content: space-between;
    overflow-x: hidden !important;

    &-btn {
        color: var(--color-secondary);
        font-family: "Open Sans", sans-serif;
        font-size: 1.3rem;
        font-weight: 700;
        letter-spacing: 1px;
        text-align: center;
        color: #fff !important;
        background-color: #131313 !important;
        padding: 1.2rem !important;
        margin-left: -2rem !important;
        border: 0 !important;

        &:hover {
            text-decoration: none;
            background-color: var(--color-secondary) !important;
            border-color: var(--color-secondary) !important;
            color: #fff !important;
            cursor: pointer;
        }
    }
}

.model-text {
    font-size: 1.4rem !important;
    font-weight: 600;
    margin-right: 2rem;

    &-span {
        font-size: 2rem;
    }
}

.close {
    display: flex !important;
    justify-content: flex-end !important;
    color: #000 !important;
    font-size: 2.5rem !important;
}

.l-height {
    line-height: 2 !important;
    letter-spacing: 0.05em;
}

.banner {
    overflow-x: hidden !important;

    &-votre {
        background-image: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.6),
                rgba(0, 0, 0, 0.2)
            ),
            url("/assets/img/7.png");
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        max-width: 100%;
    }

    &-sciatica {
      background-image: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.6),
              rgba(0, 0, 0, 0.2)
          ),
          url("../img/sciatica/2149173491.jpg");
      background-position: 50% 50%;
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      max-width: 100%;
  }


    &-adulte {
        background-image: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.6),
                rgba(0, 0, 0, 0.2)
            ),
            url("/assets/img/6.png");
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        max-width: 100%;
    }

    &-bebe {
        background-image: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.6),
                rgba(0, 0, 0, 0.2)
            ),
            url("/assets/img/8.png");
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        max-width: 100%;
    }

    &-sport {
        background-image: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.6),
                rgba(0, 0, 0, 0.2)
            ),
            url("/assets/img/15.png");
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        max-width: 100%;
    }

    &-femme {
        background-image: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.6),
                rgba(0, 0, 0, 0.2)
            ),
            url("/assets/img/13.jpg");
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        max-width: 100%;
    }

    &-urgence {
        background-image: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.6),
                rgba(0, 0, 0, 0.2)
            ),
            url("/assets/img/17.png");
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        max-width: 100%;
    }

    &-temps {
        background-image: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.6),
                rgba(0, 0, 0, 0.2)
            ),
            url("../img/new/femme-agee-souffrant-douleurs-epaule-femme-agee-souffre-douleur-terrible.jpg");
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        max-width: 100%;
    }

    &-apres {
        background-image: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.6),
                rgba(0, 0, 0, 0.2)
            ),
            url("../img/new/gros-plan-physiotherapeute-travaillant-patient-clinique.jpg");
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        max-width: 100%;
    }

    &-douleur {
        background-image: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.6),
                rgba(0, 0, 0, 0.2)
            ),
            url("../img/new/patient-faisant-readaptation-physique-aide-par-therapeutes.jpg");
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        max-width: 100%;
    }


    
    &_content {
        padding: 10rem 0;
        overflow-x: hidden !important;

        &-title {
            font-family: "Playfair Display", serif !important;
            font-size: 8rem;
            line-height: 1.1;
            font-weight: 400;
            text-transform: uppercase;
            letter-spacing: 0.4rem;
            color: #fff;
            text-align: center;

            @media only screen and (max-width: 768px) {
                font-size: 4rem;
            }
        }
    }
}

.text-left {
    text-align: left !important;
    font-weight: 400 !important;
}

.dropdown:hover > .dropdown-menu {
    display: block;
}

.dropdown > .dropdown-toggle:active {
    /*Without this, clicking will make it sticky*/
    pointer-events: none;
}

.contact {
    color: #ffffff;
    background-color: #387781;
    width: 100%;
    max-width: 100%;
    padding: 5rem 0;
    overflow-x: hidden !important;

    &_boxes {
        margin-bottom: -2rem !important;
    }

    &_box {
        padding: 3rem;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        &-icon {
            color: var(--color-primary);
            font-size: 6rem;
            text-align: center;
        }

        &-title {
            font-family: "Roboto", sans-serif;
            font-weight: 700;
            letter-spacing: 0.2rem;
            font-size: 2rem;
            color: #131313;
            line-height: 1.2;
            margin: 2rem 0 0;
        }

        &-p {
            font-family: "Open Sans", sans-serif;
            margin: 1.9rem 0 0;
            color: #131313;
            font-size: 1.6rem;
            line-height: 1.6;
            font-weight: 400;
            text-align: center;
        }
    }

    &_form {
        width: 72rem;
        min-height: 72.3rem;
        box-shadow: 0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.4);
        height: auto;
        color: #111111;
        background-color: #f2f2f2;
        padding: 1rem;
        margin: 0 auto;

        @media only screen and (max-width: 768px) {
            width: 100%;
            max-width: 100%;
        }

        &-header {
            &--title {
                font-family: "Roboto", sans-serif;
                font-weight: 700;
                letter-spacing: 0.2rem;
                font-size: 4.8rem;
                color: var(--color-primary);
                line-height: 1.2;
                margin: 3.6rem 4rem 0;
                text-align: center;

                @media only screen and (max-width: 768px) {
                    font-size: 4rem;
                }
            }
        }

        &-form {
            width: 51.5rem;
            margin: 2rem auto 0;
            padding: 1rem;

            @media only screen and (max-width: 768px) {
                width: 100%;
                max-width: 100%;
            }
        }

        &-input {
            color: #111111;
            background-color: #ffffff !important;
            display: block;
            width: 100%;
            padding: 1rem 1.2rem !important;
            transition: border-color ease-in-out 0.15s,
                box-shadow ease-in-out 0.15s;
            border: none;
            height: auto;
            font-size: 1.8rem;
            margin: 1rem 0;
        }

        &-button {
            display: flex;
            justify-content: center;
            margin-top: 2rem;

            &--btn {
                color: var(--color-secondary);
                font-family: "Open Sans", sans-serif;
                font-size: 1.6rem;
                font-weight: 700;
                letter-spacing: 1px;
                text-align: center;
                border-radius: 5rem;
                border: 2px solid;
                border-color: #f3f3f3 !important;
                color: var(--color-primary) !important;
                background-color: transparent !important;
                padding: 1.2rem 7.8rem 1.3rem !important;
                text-transform: uppercase;

                &:hover {
                    text-decoration: none;
                    background-color: var(--color-secondary) !important;
                    border-color: var(--color-secondary) !important;
                    color: #fff !important;
                    cursor: pointer;
                }
            }
        }
    }
}

.therapie {
    background-image: url("/assets/img/therapie.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
    text-align: center;
    z-index: 10;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    position: relative;
    z-index: 0;
    padding: 4rem 8rem;
    margin: 5rem 0;

    @media only screen and (max-width: 1200px) {
        padding: 4rem 0 !important;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 0.3;
        z-index: -1;
        transition: 0.5s;
    }

    #wrap {
        display: flex;
        align-items: center;
    }

    &_content {
        padding: 40% 4rem;
        @media only screen and (max-width: 990px) {
            padding: 0 4rem !important;
        }
    }

    &_bar {
        background: #fff;
        padding: 2rem;
        box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.15);
        border: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 2rem 0;
        @media only screen and (max-width: 768px) {
            flex-direction: column;
        }

        &-title {
            font-family: "Roboto", sans-serif;
            font-weight: 700;
            font-size: 3.6rem;
            color: var(--color-secondary);
            line-height: 1.2;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 2.1rem;
            text-align: left;
            width: 22rem;
            @media only screen and (max-width: 768px) {
                font-size: 2rem;
                width: fit-content;
            }
        }

        &-p {
            font-family: "Open Sans", sans-serif;
            color: #131313;
            font-size: 1.3rem;
            line-height: 1.6;
            font-weight: 400;
            text-align: left;
            width: 45rem;
            @media only screen and (max-width: 768px) {
                width: fit-content;
                font-size: 1.8rem;
            }
        }
    }
}

.urgence {
    position: relative;
    padding: 0 10rem;
    margin: 10rem 0;

    @media only screen and (max-width: 768px) {
        padding: 0 !important;
    }

    &_background {
        padding: 25rem 0;
        border: 2rem solid var(--color-secondary);
        position: relative;

        @media only screen and (max-width: 1350px) {
            display: none;
        }
    }

    &_box {
        box-shadow: 0 0.1rem 0.6rem rgba(0, 0, 0, 0.1),
            0 0.2rem 2.4rem rgba(0, 0, 0, 0.2);
        border: 0;
        width: 50%;
        max-width: 50%;
        position: absolute;
        top: -8%;
        left: 25%;
        display: flex !important;
        justify-content: center !important;
        background-color: #fff;

        @media only screen and (max-width: 1350px) {
            position: relative;
            width: 100%;
            max-width: 100%;
            left: 0;
        }

        &-content {
            padding: 7rem 3rem 10rem 3rem;
            display: flex;
            flex-direction: column;
            align-items: center;

            &--btn {
                color: var(--color-secondary);
                font-family: "Open Sans", sans-serif;
                font-size: 1.6rem;
                font-weight: 700;
                letter-spacing: 1px;
                text-align: center;
                border-radius: 5rem;
                border: 2px solid;
                border-color: #d59017 !important;
                color: #fff !important;
                background-color: var(--color-primary) !important;
                padding: 1rem 1.5rem !important;
                text-transform: uppercase;
                margin-top: 4rem;

                &:hover {
                    text-decoration: none;
                    background-color: var(--color-secondary) !important;
                    border-color: var(--color-secondary) !important;
                    color: #fff !important;
                    cursor: pointer;
                }
            }
        }

        &-image {
            width: 100%;
            max-width: 100%;
            height: 100%;

            &--img {
                width: 100%;
                max-width: 100%;
                height: 100%;
            }
        }
    }
}

.cabinet {
    padding: 5rem 0;

    &_boxes {
        margin-bottom: 2rem;
    }

    &_box {
        background-color: #f3f3f3;
        padding: 2rem 3rem 7rem 3rem;
        width: 100%;
        max-width: 100%;
        height: 100%;
        border-radius: 1rem;

        &-title {
            font-family: "Roboto", sans-serif;
            font-weight: 500;
            letter-spacing: 0.2rem;
            font-size: 3rem;
            color: #131313;
            line-height: 1.2;
            margin-bottom: 3rem !important;
        }

        &-p {
            font-family: "Roboto", sans-serif;
            color: #131313;
            font-size: 1.6rem;
            line-height: 1.6;
            font-weight: 400;
            text-align: left;
            letter-spacing: 0.05rem;
        }

        &-icon {
            font-size: 0.7rem;
            font-weight: 100 !important;
        }
    }

    &_bottom {
        background: var(--color-primary);
        padding: 30rem 0 1rem 0;
        margin-top: -30rem !important;
    }
}

.advantages {
    padding: 5rem 0;

    &_content {
        padding: 4rem 8rem;
        background-color: #f3f3f3;
        margin: 2rem 0;

        @media (max-width: 768px){
            padding: 2rem 4rem
        }

        &-title {
            font-family: "Playfair Display", serif !important;
            font-size: 4rem;
            line-height: 1.1;
            font-weight: 500;
            color: #131313;
            margin-bottom: 3rem !important;
            margin-top: 1rem !important;

            @media only screen and (max-width: 768px) {
                font-size: 3rem;
            }
        }
    }
}

.b-left {
    border-left: 1.5rem solid var(--color-secondary) !important;
}

.b-right {
    border-right: 1.5rem solid var(--color-secondary) !important;
}

.function {
    &_circle {
        background: var(--color-primary);
        border-radius: 50%;
        width: 25rem;
        height: 25rem;
        margin: 0 auto;
    }

    &_content {
        margin-top: -20rem !important;
    }
}

.adulte {
    #adulte-wrap {
        display: flex;
        align-items: center;
    }

    &_image {
        &-img {
            width: 100%;
            max-width: 100%;
            @media (max-width: 400px) {
                margin-top: 2rem;
            }
        }
    }

    &_content {
        &-ul {
            list-style: none;
            font-family: "Roboto", sans-serif;
            color: #131313;
            font-size: 1.6rem;
            line-height: 1.7;
            font-weight: 400;
            letter-spacing: 0.08rem;
            text-align: center;

            @media only screen and (max-width: 768px) {
                text-align: left;
            }

            &--li {
                margin: 0.5rem 0;
            }
        }
    }
}

.unfolding {
    padding-top: 7rem;
    padding-bottom: 3rem;

    &_circle {
        width: 7rem;
        height: 7rem;
        background: var(--color-secondary);
        border-radius: 50%;
    }

    &_gallery {
        margin-top: 5rem;

        &-img {
            width: 100%;
            max-width: 100%;
            height: 35vh;
        }
    }
}

.technique {
    padding: 5rem 0;
}

.professional {
    padding: 5rem 0;

    &_image {
        margin-top: -10rem !important;
        order: 1;

        @media only screen and (max-width: 768px) {
            margin-top: 0 !important;
        }

        &-img {
            width: 30rem;
            max-width: 30rem;
            height: 30rem;
            border-radius: 50%;
            position: relative;
            opacity: 0.8;
        }
    }

    &_content2 {
        margin-right: -10rem !important;
        z-index: -1;
        order: 2;

        @media only screen and (max-width: 768px) {
            margin-right: 0 !important;
        }
    }

    &_content {
        margin-left: -15rem !important;
        margin-top: 5rem !important;
        z-index: 10 !important;
        order: 2;

        @media only screen and (max-width: 768px) {
            margin-left: 0 !important;
        }
    }
}

.b-bottom {
    border-bottom: 1rem solid var(--color-primary);
}

.z-2 {
    z-index: 2 !important;
}

.z-0 {
    z-index: 0 !important;
}

.height-50 {
    height: 50% !important;
    max-height: 50% !important;
}

.faire {
    padding-bottom: 5rem;

    &_box {
        &-1 {
            width: 100%;
            max-width: 100%;
            height: 100%;
            background-color: var(--color-primary);
            padding: 1rem 3rem 7rem 3rem;
            border-radius: 4rem;
        }

        &-2 {
            width: 100%;
            max-width: 100%;
            height: 100%;
            background-color: #fff;
            padding: 1rem 3rem 7rem 3rem;
            border-radius: 4rem;
            box-shadow: 0 1.6rem 4rem rgba(0, 0, 0, 0.12);
            border: 0;
        }
    }
}

.chez {
    display: flex;
    align-items: center;
    width: 100vw;
    margin-bottom: 3rem;
    @media (max-width: 960px) {
        justify-content: center;
        flex-direction: column;
    }
    .left {
        width: 50%;
        @media (max-width: 960px) {
            width: 100%;
        }
        .img-wrap {
            width: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            img {
                height: 30rem;
                width: 76%;
                object-fit: cover;
            }
        }
    }
    .right {
        width: 50%;
        @media (max-width: 960px) {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .text-wrap {
            width: 80%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            @media (max-width: 960px) {
                width: 90%;
            }
            .head {
                font-size: 3rem;
                font-weight: 500;
                text-align: center;
            }
            p {
                font-size: 1.6rem;
                text-align: center;
            }
        }
    }
}
.chez-two {
    @extend .chez;
    @media (max-width: 960px) {
        flex-direction: column-reverse;
    }
    .right {
        .text-wrap {
            margin-left: 4rem;
            @media (max-width: 960px) {
                margin-left: 0;
            }
        }
    }
}

.b-white {
    border-bottom: 1.8rem solid #fff;
    margin-left: -3rem !important;
    margin-right: -3rem !important;
}

.b-green {
    border-bottom: 1.8rem solid var(--color-secondary);
    margin-left: -3rem !important;
    margin-right: -3rem !important;
}

.consultations {
    position: relative;

    &_background {
        position: relative;

        @media only screen and (max-width: 990px) {
            display: none;
        }

        &-img {
            width: 100%;
            max-width: 100%;
        }
    }

    &_content {
        position: absolute;
        top: 15%;
        margin: 0 auto;

        @media only screen and (max-width: 990px) {
            position: relative;
        }

        &-p {
            padding: 0 20rem;
            font-weight: 500;

            @media only screen and (max-width: 990px) {
                padding: 0 !important;
            }
        }
    }
}

// Imports
@import "bootstrap";

.dropdown.active {
    a.dropdown-item {
        color: $body-color !important;
        &.active {
            color: $white !important;
        }
    }
}

#navbar-fixed-phone {
    display: none;
}

.navbar-main.fixed {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 999;
    background: inherit;
    #navbar-fixed-phone {
        display: block;
    }
    .navbar-brand {
        position: relative;
        top: 0px;
        .Navbar_image-img {
            height: 60px !important;
            width: auto;
        }
        .Navbar_image-content--name {
            font-size: 2.6rem;
        }
        .Navbar_image-content--designation {
            font-size: 2rem;
        }
    }
}

#fix-phone {
    position: fixed;
    right: 0px;
    bottom: 30px;
    transform: translateX(180px);
    padding: 10px 20px;
    font-size: 20px;
    letter-spacing: 2px;
    background: #d59017;
    border: 2px solid #d59017;
    color: white;
    transition: 0.3s ease-out;
    svg {
        margin-right: 15px;
        display: inline-block;
        position: relative;
        top: 4px;
    }
    &:hover {
        background: #262626;
        text-decoration: none;
        transform: translateX(2px);
    }
}

#page404{
    background: #E8FDFF;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    color: black;
    padding: 5vh 10vw;
    @media (max-width: 768px){
        flex-direction: column;
    }
    .left, .right{
        width: 40vw;
        @media (max-width: 768px){
            width: 80vw
        }
        display: flex;
        flex-direction: column;
        justify-content: center;
        img{
            max-height: 300px;
            width: auto;
            object-fit: contain;
        }
    }
    h1{
        font-size: 30px;
    }
    p{
        font-size: 20px
    }
}

.carousel_content-1{
    @media (max-width: 768px){
        height: fit-content;
        padding: 50px 25px;
        p{
            font-size: 40px !important;
        }
        .carousel_content-para--p{
            font-size: 30px !important;
            line-height: 3rem;
        }
    }
}

.navbar-toggler{
    outline: none !important;
    position: absolute;
    right: 25px;
    top: -60px;
}

.fixed{
    .navbar-toggler{
        top: 30px;
    }
}

.nav-rdv{
    margin-left: 10px
}

.o-cover{
    object-fit: cover;
}

@media (max-width: 768px){
    #navbar-fixed-phone{
        margin: 15px 0px 25px 0px;
    }
    .carousel_content-para{
        h1{
            font-size: 4rem !important
        }
    }
    .kQhEsC{
        transform: scale(0.7);
        transform-origin: bottom left;
    }
}